import React, {useContext, useEffect, useRef, useState} from 'react';
import Marquee from "react-fast-marquee";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiamond} from "@fortawesome/free-solid-svg-icons";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const News = () => {

    const {storeblog} = useContext(Context)
    const lastEL = useRef()
    const logoShort = require('../../images/logo-short.png');

    const currencyDefault = {
        USD: 0.01114869,
        EUR: 0.01016068,
        CNY: 0.0793078,
    }
    const [currency, setCurrency] = useState(currencyDefault);

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const response = await fetch('https://www.cbr-xml-daily.ru/latest.js');
        //         const {rates} = await response.json();
        //         const {USD, EUR, CNY} = rates;
        //         setCurrency({USD: 1 / USD, EUR: 1 / EUR, CNY: 1 / CNY});
        //     } catch (error) {
        //         console.error('Помилка:', error);
        //     }
        // };
        // fetchData();

    }, []);

    return (
        <div className='d-flex justify-content-start border-bottom'>
            <div className=''>
                <img className='short_logo_news_line' src={logoShort} alt=""/>
            </div>
            <Marquee autoFill={true} pauseOnHover={true}>
                {
                    storeblog.posts.map((e, i) => {
                        return (
                            <div key={i} className='d-flex align-items-center'>
                                <p  className="inline-block my-0 mx-2 news-item">{e.title}</p>
                                <FontAwesomeIcon key={i} icon={faDiamond} style={{height: '6px'}} className='text-danger px-2'/>
                                {/*{*/}
                                {/*    (i % 5 == 0) ?*/}
                                {/*        <>*/}
                                {/*            <p className="inline-block my-0 mx-2 news-item">Курс ЦБ сейчас:*/}
                                {/*                USD <strong>{currency.USD.toFixed(2)}</strong> -*/}
                                {/*                EUR <strong>{currency.EUR.toFixed(2)}</strong> -*/}
                                {/*                CNY <strong>{currency.CNY.toFixed(2)}</strong></p>*/}
                                {/*            <FontAwesomeIcon icon={faDiamond} style={{height: '6px'}} className='text-danger px-2'/>*/}
                                {/*        </>*/}
                                {/*        : false*/}
                                {/*}*/}
                            </div>
                        )
                    })
                }
            </Marquee>
        </div>
    );
};
export default observer(News);
