import React, {useContext, useEffect, useState} from 'react';
import "../Posts/ArticleItem.css";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const BannerItem = ({link, post, title, id, articleSeoName, bannerSeoName}) => {
    const {storeblog} = useContext(Context);
    const [photoSRC, setPhotoSRC] = useState('');


    useEffect(()=>{
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(post, 'text/html');
        const imgTags = parsedHtml.getElementsByTagName('img');
        for (let i = 0; i < imgTags.length; i++) {
            const src = imgTags[0].getAttribute('src');
            setPhotoSRC(src);
        }
    },[]);

    const updateUtmContent = (urlParams, bannerSeoName, articleSeoName) => {

        let params = new URLSearchParams(urlParams);

        console.log(params , urlParams , 'params')
        // Завжди встановлюємо значення utm_content і utm_term
        params.set('utm_content', articleSeoName);
        params.set('utm_term', bannerSeoName);

        // Перетворюємо назад у строку параметрів
        return `?${params.toString()}`;
    }

    const params = updateUtmContent(storeblog.urlParams, bannerSeoName, articleSeoName)

    return (
        <div className="col">
            <a href={link + params} className=" article_side_banner">

                    <img className="article_side_banner-img" src={photoSRC} alt="image"/>
                    <p className="article_side_banner-title">{title}</p>
            </a>
        </div>
    );
};

export default observer(BannerItem);