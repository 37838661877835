import {makeAutoObservable} from "mobx";
import {getBanners, getPopupBanners, getBackBtnLink} from "../publickArticle/http/bannersApi";
import {getPosts} from "../publickArticle/http/postsApi";

export class Storeblog {
    posts = []
    allPosts = []
    selectedCategory = ''
    banners = []
    randomBanners = []
    popupBanners = []
    isLoading = false
    isError = ''
    urlParams = ''
    backBtnLink = []

    articleSeoName = ''

    totalPages = 0
    pages = 1


    constructor() {
        makeAutoObservable(this)
    }

    setPosts(posts) {
        this.posts = posts;
    }

    setAllPosts(posts) {
        this.allPosts = posts;
    }

    setBanners(banners) {
        this.banners = banners;
    }
    setRandomBanners(randomBanners) {
        this.randomBanners = randomBanners;
    }

    setSelectedCategory(type) {
        this.setPages(1)
        this.setTotalPages(0)
        this.setPosts([])
        this.selectedCategory = type;
    }

    setIsLoading(loading) {
        this.isLoading = loading;
    }

    setIsError(error) {
        this.isError = error;
    }

    setUrlParams(params) {
        this.urlParams = params;
    }

    setPages(pages) {
        this.pages = pages;
    }

    setTotalPages(pages) {
        this.totalPages = pages;
    }
    setPopupBanners(banners){
        this.popupBanners = banners
    }
    setBackBtnLink(data){
        this.backBtnLink = data
    }

    async GetPosts(page, limit, category) {
        try {
            this.setIsLoading(true)
            const data = await getPosts(page, limit, category)
            const posts = data.posts
            const pages = data.page
            const totalPages = data.totalPages

            if (category.length !== 0) {
                const result = posts.filter(e => e.category === category)
                this.setPosts([...this.posts, ...result])
            } else {
                this.setPosts([...this.posts, ...posts])
            }
            this.setTotalPages(totalPages)
            this.setPages(+pages)
            this.setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    async GetBanners(page, limit) {
        try {
            const data = await getBanners(page, limit)
            this.setBanners(data.banner)

            let randomArrBanner = [];
        
            data.banner.map( (e,i) => {
        
                    if( i < 6) {
                        randomArrBanner.push(data.banner[i]);
                    }
            })

            for (let i = randomArrBanner.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1)); 
                [randomArrBanner[i], randomArrBanner[j]] = [randomArrBanner[j], randomArrBanner[i]];
            }
                

            this.setRandomBanners(randomArrBanner)

        } catch (e) {
            console.log(e)
        }
    }


    async GetPopupBanners(){
        try {
            const data = await getPopupBanners();
            this.setPopupBanners(data);
        } catch (e) {
            console.log(e);
        }
    }

    async GetBackBtnLink(){
        try {
            const data = await getBackBtnLink();
            this.setBackBtnLink(data);

        } catch (e) {
            console.log(e);
        }
    }

    async GetUrlParams() {
        try {
            const currentURL = window.location.href;
            const urlParameters = currentURL.split('?')[1];

            if (urlParameters) {
                const paramsStart = currentURL.indexOf('?');
                const params = paramsStart !== -1 ? `?${currentURL.substring(paramsStart + 1)}` : '';

                const cookieExists = this.checkCookieExists('paramsString');
                if (cookieExists == null) {
                    this.setCookie('paramsString', params, 10)
                    this.setUrlParams(params);
                } else {
                    this.setUrlParams(cookieExists);
                }
                // console.log('Параметри URL:', urlParameters);
            } else {
                const cookieExists = this.checkCookieExists('paramsString');
                if (cookieExists !== null) {
                    this.setUrlParams(cookieExists);
                    // console.log('немає в юрл і є в кукі' , cookieExists)
                } else {
                    // console.log('немає в юрл і немає в кукі')
                }
                // console.log('URL не містить параметрів.');
            }

        } catch (e) {
            console.log(e)
        }
    }

    setCookie(cookieName, cookieValue, expirationDays) {
        const d = new Date();
        d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    checkCookieExists(cookieName) {
        const cookies = decodeURIComponent(document.cookie);
        const cookiesArray = cookies.split('; ');
        let result = '';
        cookiesArray.forEach(e => {
            if (e.indexOf(cookieName) !== -1) {
                result = e.replace(`${cookieName}=`, '');
            } else {
                result = null;
            }
        })
        return result;
    }

    getRandomNumber(max) {
        // Генерує випадкове число від 0 до (max - 1)
        return Math.floor(Math.random() * max);
    }

}